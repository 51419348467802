import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig, MatDialogConfig, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { MAT_DATE_LOCALE } from '@angular/material';
import { HttpModule } from '@angular/http';
import { ProgettiComponent } from './pages/progetti/progetti.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { VendorComponent } from './pages/vendor/vendor.component';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'it' },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { ...new MatDialogConfig(), maxWidth: '100vw', maxHeight: '95vh' } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
